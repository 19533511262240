import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		height: '100%',
		justifyContent: 'center',
		gap: theme.spacing.xlarge,
		padding: `${theme.spacing.large} 0`,
		color: theme.palette.neutral[50],
	},
	description: {
		color: theme.palette.neutral[50],
	},
	title: {
		color: theme.palette.neutral[60],
	},
}));
