import { type FC } from 'react';

import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { type MicroCopy } from '@vakantiesnl/types';

import { useStyles } from './ErrorMessage.styles';

type Props = {
	microCopy: MicroCopy;
	title: string;
	description: string;
};

export const ErrorMessage: FC<Props> = ({ microCopy, title, description }) => {
	const { classes } = useStyles();
	const { isTabletOrIsDesktop } = useBreakpoints();

	return (
		<div className={classes.container}>
			<Typography as="h1" className={classes.title} variant={isTabletOrIsDesktop ? 'heading2Xl' : 'headingXl'}>
				{title}
			</Typography>
			<Typography as="p" className={classes.description} variant={isTabletOrIsDesktop ? 'bodyLg' : 'bodyMd'}>
				{description}
			</Typography>
			<Button href="/" asText>
				{microCopy['redirect.homepage.failure']}
			</Button>
		</div>
	);
};
